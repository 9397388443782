import React from "react";
import logoWealComeImg from "../img/WC-2-white.png";
import {Link} from "gatsby";
import {AnchorLink} from "gatsby-plugin-anchor-links";

const MenuLevel1 = () =>
    <ul className="navbar-nav ml-auto">
        <li className="nav-item">
            <AnchorLink className="nav-link" to="/#competences" title="Compétences"/>
        </li>
        <li className="nav-item">
            <AnchorLink className="nav-link" to="/#videopresentation"
                        title="Vidéo de présentation"/>
        </li>
        <li className="nav-item">
            <AnchorLink className="nav-link" to="/realisations-projets"
                        title="Réalisations de projets"/>
        </li>
        <li className="nav-item">
            <AnchorLink className="nav-link" to="/formations"
                        title="Nos formations"/>
        </li>
        <li className="nav-item">
            <Link to="/temoignages" className="nav-link">Témoignages</Link>
        </li>
        <li className="nav-item">
            <Link to="/contact" className="nav-link">Contact</Link>
        </li>
        <li className="nav-item">
            <Link to="/blog" className="nav-link">Blog</Link>
        </li>
    </ul>;

export const Header = ({fixedTop, backgroundTransition}) => {
    return <React.Fragment>
        <header className="header">
            <nav className={"navbar navbar-expand-lg" + (fixedTop ? " fixed-top" : '') + (backgroundTransition ?
                ' bg-transparent' : ' bg-no-transparent')}>
                <div className="container-fluid">
                    <AnchorLink className="nav-link navbar-brand" to="/#home" title="Accueil">
                        <img
                            src={logoWealComeImg}
                            alt="logo"
                            width="200"
                            className="img-fluid"
                        />
                    </AnchorLink>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="lni lni-menu"/>
                    </button>

                    <div
                        className="collapse navbar-collapse main-menu"
                        id="navbarSupportedContent">
                        <MenuLevel1/>
                    </div>
                </div>
            </nav>
        </header>
    </React.Fragment>;
};