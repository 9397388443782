import React from 'react';
import logoWealComeImg from "../../img/WC-4.png";

export const Footer = ({whiteBackground}) => {
    return (
        <div>
            <footer id="contact" className={"footer-section " + (whiteBackground ? "" : "gray-light-bg")}>
                <div className="footer-top pt-5 pb-5">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-lg-4 mb-4 mb-lg-0">
                                <div className="footer-nav-wrap">
                                    <img src={logoWealComeImg} alt="footer logo" width="180"
                                         className="img-fluid mb-3" style={{marginTop: -11}}/>
                                    <p>Société de conseil spécialisée en conception et développement logiciel sur
                                        Paris.</p>
                                    <p>Nous réalisons vos projets d'applications.<br/>
                                        Nous vous coachons sur nos pratiques avec un accompagnement intense et personnalisé.<br/>
                                        Nous vous formons sur nos pratiques afin de faire de vous de
                                        véritables professionnels du code.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 ml-auto mb-4 mb-lg-0">
                                <div className="footer-nav-wrap">
                                    <h5 className="mb-3">Coordonnées</h5>
                                    <ul className="list--unstyled">
                                        <li className="mb-2"><span
                                            className="lni lni-chevron-right-circle mr-2"/><strong>Adresse : </strong>
                                            192 avenue de la Division Leclerc, 95160, Montmorency
                                        </li>
                                        <li className="mb-2"><span
                                            className="lni lni-chevron-right-circle mr-2"/><strong>Tel : </strong> <a
                                            href="tel:+33609885102">06.09.88.51.02</a></li>
                                        <li className="mb-2"><span
                                            className="lni lni-chevron-right-circle mr-2"/><strong>Email : </strong><a
                                            href="mailto:contact@wealcomecompany.com">contact@wealcomecompany.com</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 ml-auto mb-4 mb-lg-0">
                                <div className="footer-nav-wrap">
                                    <h5 className="mb-3">Réseaux sociaux</h5>
                                    <ul className="list-unstyled">
                                        <li className="mb-2"><span
                                            className="lni lni-chevron-right-circle mr-2"/>
                                            <a href="https://www.linkedin.com/company/wealcomecompany/">
                                                <strong>LinkedIn : </strong>
                                                Page WealCome
                                            </a>
                                        </li>
                                        <li className="mb-2"><span
                                            className="lni lni-chevron-right-circle mr-2"/>
                                            <a href="https://twitter.com/MichaelWealcome">
                                                <strong>Twitter : </strong>
                                                Compte WealCome
                                            </a>
                                        </li>
                                        <li className="mb-2"><span
                                            className="lni lni-chevron-right-circle mr-2"/>
                                            <a
                                                href="https://www.youtube.com/channel/UCdcsr2L2WC0OON39Ar3hBKQ">
                                                <strong>Youtube : </strong>
                                                Chaîne WealCome
                                            </a>
                                        </li>
                                        <li className="mb-2"><span
                                            className="lni lni-chevron-right-circle mr-2"/>
                                            <a
                                                href="https://join.slack.com/t/wealcome/shared_invite/zt-26ajdscdc-YsHo1cEvo40YBj6UW4sSFg">
                                                <strong>Slack : </strong>
                                                Slack WealCome <br/>(Communauté Craft) -  <strong><span style={{color: '#28a745'}}>+ de 3000 membres, rejoins-nous !</span></strong>
                                            </a>
                                        </li>
                                        <li className="mb-2"><span
                                            className="lni lni-chevron-right-circle mr-2"/>
                                            <a
                                                href="https://open.spotify.com/show/4zVCbAw9BvcufC9GMrjrNd">
                                                <strong>Podcasts : </strong>
                                                La vision du développeur d'élite
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom gray-light-bg pt-4 pb-4">
                    <div className="container">
                        <div className="row text-center text-md-center align-items-center">
                            <div className="col-md-12 col-lg-12"><p
                                className="text-md-center copyright-text pb-0 mb-0">
                                Copyright © 2023 WealCome, Tous droits réservés.
                            </p></div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
};
